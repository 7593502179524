import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{staticClass:"custom-form white py-3 px-2 shadow1 rounded",on:{"submit":function($event){$event.preventDefault();return _vm.getSaleReturns.apply(null, arguments)}}},[_c(VRow,{attrs:{"dense":"","justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v("Type")]),_c(VCol,{attrs:{"cols":"9"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":['All', 'By Customer']},model:{value:(_vm.searchType),callback:function ($$v) {_vm.searchType=$$v},expression:"searchType"}})],1)],1)],1),(_vm.searchType == 'By Customer')?_c(VCol,{attrs:{"cols":"3"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Customer")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.$store.getters['customer/customers'],"loading":_vm.$store.getters['customer/loading'],"item-text":"display_text","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('customer/getCustomers')}},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"5"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.sale.dateFrom),callback:function ($$v) {_vm.$set(_vm.sale, "dateFrom", $$v)},expression:"sale.dateFrom"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.sale.dateFrom),callback:function ($$v) {_vm.$set(_vm.sale, "dateFrom", $$v)},expression:"sale.dateFrom"}})],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VRow,{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000"},attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c(VCol,{attrs:{"cols":"8"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.sale.dateTo),callback:function ($$v) {_vm.$set(_vm.sale, "dateTo", $$v)},expression:"sale.dateTo"}},'v-text-field',attrs,false),on),[_c(VIcon,{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c(VDatePicker,{model:{value:(_vm.sale.dateTo),callback:function ($$v) {_vm.$set(_vm.sale, "dateTo", $$v)},expression:"sale.dateTo"}})],1)],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"text_bg_fave",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Search")])],1)],1)],1),_c(VDivider)],1)],1),(_vm.show)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticStyle:{"display":"none"},attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"height":"26px"},on:{"click":function($event){$event.preventDefault();return _vm.print.apply(null, arguments)}}},[_vm._v("Print")])],1),_c(VCol,{attrs:{"cols":"12","id":"invoiceContent"}},[_c(VDataTable,{staticClass:"custom-data-table elevation-1",attrs:{"dense":"","show-expand":"","headers":_vm.saleReturnHeaders,"loading":_vm.$store.getters['sale/loadingSale'],"items":_vm.$store.getters['sale/saleReturns'],"search":_vm.searchSale},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"dense":"","color":"white","elevation":1}},[_c(VToolbarTitle,{staticClass:"subtitle-1"},[_vm._v("Sale Return List")]),_c(VDivider,{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c(VForm,{staticClass:"custom-form"},[_c(VTextField,{staticStyle:{"width":"300px"},attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search sale","append-icon":"mdi-magnify"},model:{value:(_vm.searchSale),callback:function ($$v) {_vm.searchSale=$$v},expression:"searchSale"}})],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"small":"","color":"success","target":"_blank"},on:{"click":function($event){return _vm.$router.push(("/sale-return-invoice/" + (item.id)))}}},on),[_vm._v(" mdi-file ")])]}}],null,true)},[_c('span',[_vm._v("Invoice")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"details__table"},[_c('tr',[_c('th',[_vm._v("SL No.")]),_c('th',[_vm._v("Product Id")]),_c('th',[_vm._v("Product Name")]),_c('th',[_vm._v("Quantity")]),_c('th',[_vm._v("Amount")])]),_vm._l((item.return_details),function(detail,i){return _c('tr',{key:i},[_c('td',{attrs:{"width":"5%"}},[_vm._v(_vm._s(i + 1))]),_c('td',[_vm._v(_vm._s(detail.product.code))]),_c('td',[_vm._v(_vm._s(detail.product.name))]),_c('td',[_vm._v(_vm._s(detail.quantity))]),_c('td',[_vm._v(_vm._s(detail.return_amount))])])})],2)])]}}],null,true)})],1)],1):_c('div',{staticClass:"white shadow1 rounded"},[_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }